import React from 'react'
import Unsubscribe from 'components/Unsubscribe'
import WhoAmI from 'components/WhoAmI'
import NotFoundPage from 'components/NotFoundPage'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import './style.css'

const AppLayout = () => {
  return (
    <Router>
      <Switch>
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/debug/whoami" component={WhoAmI} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default AppLayout
