import React from 'react'
import ReactDOM from 'react-dom'
import LoadStyles from '@driftt/tide-core/dist/styles'
import App from './layouts/App'
import './index.css'
import { initializeSentry } from 'utils'
import config from 'config'

LoadStyles()

ReactDOM.render(
  <App/>,
  document.getElementById('root')
)

config.SENTRY_KEY && setTimeout(() => initializeSentry(config.SENTRY_KEY, config.ENV_NAME), 0)
