import React, { Component } from 'react'
import qs from 'qs'
import * as Sentry from '@sentry/browser'

import { Confirm, unsubscribeChoices } from './component'
import { fetchGeolocation } from '../../api/geolocation'
import { fetchById } from '../../api/embed'
import { requestUnsubDeleteData, unsubscribeByEmailAndOrgId, unsubscribeByEncodedEmail, unsubscribe, resubscribeByEmailandOrgId, resubscribe } from '../../api/subscription'

class Unsubscribe extends Component {
  constructor(props) {
    super(props)
    this.params = this.getParams()
    this.state = {
      responded: false,
      resubscribed: false,
      unsubscribed: false,
      cantUnsub: false,
      cantResub: false,
      emailInput: this.params.email,
      unsubscribeChoice: unsubscribeChoices.UNSUB
    }
    Sentry.configureScope(function(scope) {
      scope.setExtra('params', this.params)
    })
  }

  componentWillMount() {
    if (!this.props.isGeolocationLoaded && !this.props.isFetchGeolocationPending) {
      this.props.fetchGeolocation()
    }

    if (this.props.embedNotFetched) {
      this.props.fetchEmbedById(this.params.embedId)
    }
    // setTimeout(() => this.props.setForceShowLoading(false), 1500)
  }

  getParams = () => {
    // certain email clients html decode links (i.e. "&email=ryan" -> "&amp;email=ryan")
    // looks like it's most prevalent on Windows machines running IE
    const queryParams = qs.parse(this.props.location.search)
    const params = {
      endUserId: queryParams.endUserId|| queryParams['amp;endUserId'] || queryParams['?endUserId'],
      orgId: queryParams.orgId || queryParams['amp;orgId'] || queryParams['?orgId'],
      campaignId: queryParams.campaignId || queryParams['amp;d_campaign'] || queryParams['?d_campaign'],
      email: queryParams.email|| queryParams['amp;email'] || queryParams['?email'],
      identifier: queryParams.identifier || queryParams['amp;identifier'] || queryParams['?identifier'],
      embedId: queryParams.embedId|| queryParams['amp;embedId'] || queryParams['?embedId'],
    }
    // we're removing the email from the query params and instead adding "identifier"
    if (params.identifier && !params.email) {
      try {
        params.email = atob(params.identifier)
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    return params
  }

  handleResponseClick = (reason) => {
    this.setState({ responded: true })
    if (this.params.endUserId) {
      this.props.unsubscribeByEncodedEmail({ email: this.state.emailInput, reason, endUserId: this.params.endUserId })
    }
    else {
      const orgId = this.params.orgId || this.props.orgId
      this.props.unsubscribeByEmailAndOrg({ email: this.state.emailInput, orgId })
    }
  }

  onEmailConfirmChange = (e) => {
    this.setState({ emailInput: e.target.value })
  }

  onUnsubChoiceChange = (choice) => {
    this.setState({ unsubscribeChoice: choice })
  }

  onConfirmClick = () => {
    const orgId = this.params.orgId || this.props.orgId
    if (this.state.unsubscribeChoice === unsubscribeChoices.UNSUB_AND_REQUEST_DELETE) {
      this.props.requestDeleteData({ orgId, email: this.state.emailInput, endUserId: this.params.endUserId })
      this.setState({ unsubscribed: true })
    } 
    if (this.params.endUserId) {
      this.props.unsubscribeByEncodedEmail({ email: this.state.emailInput, endUserId: this.params.endUserId })
      this.setState({ unsubscribed: true })
    } else if (this.state.emailInput && orgId !== -1) {
      this.props.unsubscribeByEmailAndOrg({ email: this.state.emailInput, orgId })
      this.setState({ unsubscribed: true })
    } else {
      this.setState({ cantUnsub: true })
    }
  }

  onResubscribeClick = () => {
    const orgId = this.params.orgId || this.props.orgId
    if (this.params.endUserId) {
      this.props.resubscribe({ email: this.state.emailInput, endUserId: this.params.endUserId })
      this.setState({ resubscribed: true })
      this.setState({ unsubscribed: false })
    } else if (this.state.emailInput && orgId !==-1) {
      this.props.resubscribeByEmailandOrgId({ email: this.state.emailInput, orgId })
      this.setState({ resubscribed: true })
      this.setState({ unsubscribed: false })
    } else {
      this.setState({ unsubscribed: true })
      this.setState({ cantResub: true })
      this.setState({ cantUnsub: false })
    }
  }

  render() {
    return <Confirm {...this.props} {...this.state} onConfirmClick={this.onConfirmClick} onResubscribeClick={this.onResubscribeClick}  handleResponseClick={this.handleResponseClick}
    onEmailConfirmChange={this.onEmailConfirmChange} onUnsubChoiceChange={this.onUnsubChoiceChange} />
  }
}

// API functions
class UnsubscribeWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isGeolocationLoaded: false,
      isFetchGeolocationPending: false,
      isGDPRApplicable: true,
      embed: null,
      embedNotFetched: true,
      embedFetchPending: false,
      embedLoadFailed: false,
      backgroundColor: null,
      logoUrl: null,
      orgId: -1,
      unsubscribePending: false,
      unsubscribeError: false,
      resubscribeError: false,
      resubscribePending: true,
      unsubRequestDeleteSuccess: false,
      requestDeleteFailed: false
    }
  }

  fetchGeolocation = async () => {
    this.setState({isFetchGeolocationPending: true})
    try {
      const { data } = await fetchGeolocation()
      this.setState({isGDPRApplicable: data.gdprApplicable})
    }
    catch (e) {
      this.setState({isFetchGeolocationFailed: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({isFetchGeolocationPending: false})
    }
  }

  fetchEmbedById = (embedId) => {
    this.setState({embedFetchPending: true})
    fetchById(embedId).then(({data}) => {
      const { configuration: {theme}} = data
      this.setState({orgId: data.orgId, backgroundColor: `#${theme.backgroundColor}`, logoUrl: theme.logoUrl, embedNotFetched: false, embedFetchPending: false})
    }).catch(() => {
      this.setState({embedLoadFailed: true, embedFetchPending: false})
    })
  }

  requestDeleteData = async ({ email, endUser, orgId }) => {
    this.setState({unsubscribePending: true})
    try {
      await requestUnsubDeleteData({email, endUser, orgId})
      this.setState({unsubRequestDeleteSuccess: true})
    }
    catch (e) {
      this.setState({requestDeleteFailed: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({unsubscribePending: false})
      this.setState({unsubRequestDeleteSuccess: false})
      this.setState({requestDeleteFailed: false})
    }
  }

  unsubscribeByEncodedEmail = async ({ email, endUserId, reason }) => {
    this.setState({unsubscribePending: true})
    try {
      await unsubscribeByEncodedEmail(({email, endUserId, reason}))
    }
    catch (e) {
      this.setState({unsubscribeError: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({unsubscribePending: false})
    }
  }

  unsubscribeByEmailAndOrg = async ({ email, orgId }) => {
    this.setState({unsubscribePending: true})
    try {
      await unsubscribeByEmailAndOrgId({email, orgId})
    }
    catch (e) {
      this.setState({unsubscribeError: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({unsubscribePending: false})
    }
  }

  unsubscribe = async (email) => {
    this.setState({unsubscribePending: true})
    try {
      await unsubscribe(email)
    }
    catch (e) {
      this.setState({unsubscribeError: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({unsubscribePending: false})
    }
  }

  resubscribe = async ({ email, endUserId }) => {
    this.setState({resubscribePending: true})
    try {
      await resubscribe({ email, endUserId })
    }
    catch (e) {
      this.setState({resubscribeError: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({resubscribePending: false})
    }
  }

  resubscribeByEmailandOrgId = async ({ email, orgId }) => {
    this.setState({resubscribePending: true})
    try {
      await resubscribeByEmailandOrgId({email, orgId})
    }
    catch (e) {
      this.setState({resubscribeError: true})
      Sentry.captureException(e)
    }
    finally {
      this.setState({resubscribePending: false})
    }
  }

  render() {
    return <Unsubscribe {...this.props} {...this.state} fetchGeolocation={this.fetchGeolocation} fetchEmbedById={this.fetchEmbedById} requestDeleteData={this.requestDeleteData} 
    unsubscribeByEncodedEmail={this.unsubscribeByEncodedEmail} unsubscribeByEmailAndOrg={this.unsubscribeByEmailAndOrg} unsubscribe={this.unsubscribe} 
    resubscribeByEmailandOrgId={this.resubscribeByEmailandOrgId} resubscribe={this.resubscribe}/>
  }
}
export default UnsubscribeWrapper
