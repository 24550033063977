import { CustomerAPI } from 'api'

export const unsubscribeByEncodedEmail = ({ email, endUserId, reason }) => {

    return CustomerAPI.post(`/unsubscribe/byIdentifier/${encodeURIComponent(window.btoa(email))}?reason=${encodeURIComponent(reason)}&endUserId=${endUserId}`)
}

export const unsubscribeByEmailAndOrgId = ({ email, orgId }) => {
    return CustomerAPI
      .post(`/unsubscribe/byEmail/`, {
        email,
        orgId
    })
}

export const requestUnsubDeleteData = ({ email, endUserId, orgId }) => {
    return CustomerAPI
      .post('/unsubscribe/delete_user_data', { email, endUserId, orgId })
}

export const unsubscribe = (email) => {
    return CustomerAPI.post('/unsubscribe/mark', { email })
}

export const resubscribe = ({email, endUserId}) => {
    return CustomerAPI.post(`/unsubscribe/resubscribe/${encodeURIComponent(window.btoa(email))}?endUserId=${endUserId}`)
}

export const resubscribeByEmailandOrgId = ({email, orgId}) => {
    return CustomerAPI.post(`/unsubscribe/resubscribe`, { email, orgId })
}