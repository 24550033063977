import { map, curry, chain, zipObj, prop } from 'ramda'
import * as Sentry from '@sentry/browser'

//used to pause on a tick, cleaner then timeouts.
// const dude = async () => {
//await pause(200)
//doSomething()
//}
export const pause = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const delay = async (fn, ms) => {
  await pause(ms)
  await fn()
}

export const isNumeric = number => {
  return !isNaN(parseFloat(number)) && isFinite(number)
}

export const uniqueID = () => `_${Math.random().toString(36).substr(2, 9)}`

// this is better debounce.
// This is basically lodash debounce with one core difference
// the initial invocation passed through debounce will execute immeadately
export const debounce = (func, wait, immediate) => {
  let timeout = null
  const minWait = 10 //minimum amount of time it takes for any given user interaction (mouse click, key stroke)

  return (...args) => {
    const callNow = immediate && !timeout

    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(this, args)
      }
    }

    if (!timeout) {
      func.apply(this, args)
      timeout = setTimeout(later, minWait)
    } else {
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }

    if (callNow) {
      func.apply(this, args)
    }
  }
}

// given a list of objects - and assuming each object has a key "id"
// this will provide an object keyed by those id's
export const byId = curry(
  (list) => chain(
    zipObj,
    map(prop('id'))
  )(list)
)

export const initializeSentry = (dsn, environment) => {
  Sentry.init({
    dsn,
    environment,
  })
}
