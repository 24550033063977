import { getAccessToken } from '@driftt/web-auth'
import * as _ from 'lodash'

export const truncateWords = (string, length) => {
  const array = string.split(' ')
  if (array.length > length) {
    return `${array.splice(0, length).join(' ')}...`
  }
  return string
}

const encodeQueryData = function(data) {
  const ret = []
  for (const key in data) {
    const value = data[key]
    if (_.isArray(value)) {
      _.each(value, element =>
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(element)}`)
      )
    } else if (value != null) {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  return ret.join('&')
}

const serializeObject = obj =>
  _.map(obj, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')

const isValidPassword = password => password && password.length >= 6

const getAuthorizationHeader = function(forcedAccessToken = null) {
  let accessToken = forcedAccessToken
  if (accessToken == null) {
    accessToken = getAccessToken()
  }
  if (accessToken) {
    return { Authorization: `bearer ${accessToken}` }
  }
}

const getQuery = function(paramName, values) {
  const str = values.join(`&${paramName}=`)
  return `${paramName}=${encodeURIComponent(str)}`
}

const getDomainFromEmail = function(email) {
  if (!email) {
    return ''
  }
  return __guard__(email.split('@'), x => x[1]) || ''
}

const JSON_TYPE = 'application/json'

const RequestContentType = {
  X_WWW_FORM: 'application/x-www-form-urlencoded',
  JSON: JSON_TYPE,
}

const PostJsonHeaders = {
  Accept: JSON_TYPE,
  'Content-Type': JSON_TYPE,
}

const generateRetinaImageProps = (src, retinaSrc) => ({ src, srcSet: `${src} 1x, ${retinaSrc} 2x` })

const generateBulkFetchURL = function(baseURL, ids, paramName) {
  if (paramName == null) {
    paramName = 'id'
  }
  return _.reduce(
    ids,
    function(urlAcc, id, index) {
      const urlToken = index === 0 ? '?' : '&'
      return `${urlAcc}${urlToken}${paramName}=${id}`
    },
    baseURL
  )
}

const isFileNameImage = function(fileName) {
  if (fileName) {
    const tokens = fileName.split('.')
    if (tokens.length > 1) {
      return _.includes(['jpeg', 'jpg', 'gif', 'png', 'svg'], tokens.pop())
    }
  }
}

const moveCursorToEnd = function(el) {
  const { value } = el
  el.focus()
  el.value = ''
  return (el.value = value)
}

export {
  encodeQueryData,
  getAuthorizationHeader,
  RequestContentType,
  getQuery,
  serializeObject,
  generateRetinaImageProps,
  generateBulkFetchURL,
  isValidPassword,
  isFileNameImage,
  moveCursorToEnd,
  getDomainFromEmail,
  PostJsonHeaders,
}

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}

// https://github.com/rigoneri/indefinite-article.js/blob/master/indefinite-article.js
export function a(phrase, full = true) {
  const match = /\w+/.exec(phrase)
  let word
  const l_word = word.toLowerCase()
  if (match) word = match[0]
  else return `an ${full ? l_word : ''}`.trim()

  // Specific start of words that should be preceeded by 'an'
  const alt_cases = ['honest', 'hour', 'hono']
  for (const i in alt_cases) {
    if (l_word.indexOf(alt_cases[i]) === 0) return `an ${full ? phrase : ''}`.trim()
  }

  // Single letter word which should be preceeded by `an ${phrase}`
  if (l_word.length === 1) {
    if ('aedhilmnorsx'.indexOf(l_word) >= 0) return `an ${full ? phrase : ''}`.trim()
    else return `a ${full ? phrase : ''}`.trim()
  }

  // Capital words which should likely be preceeded by `an ${phrase}`
  if (
    word.match(
      /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/
    )
  ) {
    return `an ${full ? phrase : ''}`.trim()
  }

  // Special cases where a word that begins with a vowel should be preceeded by 'a'
  const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/]
  for (const i in regexes) {
    if (l_word.match(regexes[i])) return `a ${full ? phrase : ''}`.trim()
  }

  // Special capital words (UK, UN)
  if (word.match(/^U[NK][AIEO]/)) {
    return 'a'
  } else if (word === word.toUpperCase()) {
    if ('aedhilmnorsx'.indexOf(l_word[0]) >= 0) return `an ${full ? phrase : ''}`.trim()
    else return 'a'
  }

  // Basic method of words that begin with a vowel being preceeded by `an ${phrase}`
  if ('aeiou'.indexOf(l_word[0]) >= 0) return `an ${full ? phrase : ''}`.trim()

  // Instances where y follwed by specific letters is preceeded by `an ${phrase}`
  if (l_word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/))
    return `an ${full ? phrase : ''}`.trim()

  return `a ${full ? phrase : ''}`.trim()
}
