import React, { Component } from 'react'
import { fetchUser } from 'api/user'
import { Div, H1 } from '@driftt/tide-core'

class WhoAmI extends Component {
  constructor(props) {
    super(props)
    this.state = { me: false }
  }

  componentDidMount() {
    fetchUser()
      .then(me => this.setState({ me }))
      .catch(error => console.error(error))
  }

  render() {
    const { me } = this.state
    return (
      <Div>
        {me ? 
          <Div>
            <H1>
              You are
            </H1>
            <pre>
              {JSON.stringify(me, null, "\t")}
            </pre>
          </Div>
        : 
          <H1>Error retrieving user data</H1>
        }
      </Div>
    )
  }
}

  export default WhoAmI
  