import React, { useEffect, useRef } from 'react'
import { Div, H1, P, Button } from '@driftt/tide-core'
import bodymovin from 'bodymovin'
import DIZZY_BOT from './dizzy-bot-404.json'

import './style.css'

const NotFoundPage = () => {

  const bodyMovinContainerRef = useRef(null)
  useEffect(() => {
    bodyMovinContainerRef.current && bodymovin.loadAnimation({
      container: bodyMovinContainerRef.current,
      loop: true,
      renderer: 'svg',
      autoplay: true,
      animationData: DIZZY_BOT
    })
  }, [bodyMovinContainerRef])

  return (
    <Div className="not-found-page">
      <Div className="not-found-animation">
      <div ref={bodyMovinContainerRef} />
      </Div>
        <H1 className="not-found-header">Oh man, you stumped DriftBot!</H1>
        <P className="not-found-subtext">We can’t seem to find what you’re looking for…maybe it’s time for a reboot</P>
        <Div><Button href="/home">Go home</Button></Div>
    </Div>
  )
}

export default NotFoundPage
