import config from 'config'
import {createAuthedHttpClient} from '@driftt/web-auth'
import Qs from 'qs'

export const DriftAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.DRIFT_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
  }
})

export const CustomerAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.CUSTOMER_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
  }
})

export const WidgetCacheAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.WIDGET_CACHE_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
  }
})

export const SyncAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.SYNC_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
  }
})

export const IntegrationAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.INTEGRATION_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
  }
})

export const ContactsAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.CONTACTS_API,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
  }
})