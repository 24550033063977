import React from 'react'
import chroma from 'chroma-js'
import { Emoji } from 'emoji-mart'
import { Input, Button, Loader, Div, A, P, H1, H2 } from '@driftt/tide-core'
import { Icon } from '@driftt/tide-icons'
import cx from 'classnames'

import { encodeQueryData } from 'utils/Helpers'

import './style.styl'

const REASONS = [
  {
    emoji: ':broken_heart:',
    description: 'Your emails are not relevant to me',
  },
  {
    emoji: ':man-facepalming:',
    description: 'Your emails are sent too frequently',
  },
  {
    emoji: ':no_entry_sign:',
    description: 'I no longer want to receive these emails',
  },
  {
    emoji: ':woman-shrugging:',
    description: 'I don\'t remember signing up for this',
  },
  {
    emoji: ':speech_balloon:',
    description: 'Other reason',
  },
]

export const unsubscribeChoices = {
  UNSUB: 'UNSUB',
  UNSUB_AND_REQUEST_DELETE: 'UNSUB_AND_REQUEST_DELETE',
}

const powerByDriftLinkParams = {
  utm_source: 'email',
  utm_campaign: 'email-unsubscribed',
}

const POWERED_BY_URL = `https://www.drift.com/powered-by?${encodeQueryData(powerByDriftLinkParams)}`

export const Loading = () => (
  <Div className="unsubscribe-confirm-loader-container">
    <Div>
      <H2>Fetching your details...</H2>
      <Loader className="unsubscribe-confirm-loader" />
    </Div>
  </Div>
)

export const Confirm = ({
  backgroundColor,
  logoUrl,
  responded,
  emailInput,
  onEmailConfirmChange,
  unsubscribed,
  handleResponseClick,
  onConfirmClick,
  onResubscribeClick,
  unsubscribeError,
  unsubscribeErrorMessage,
  resubscribed,
  resubscribeError,
  resubscribeErrorMessage,
  cantUnsub,
  cantResub,
  embedLoadFailed,
  isGdprConsentRequired,
  unsubscribeChoice,
  onUnsubChoiceChange,
  confirmDisabled,
  unsubRequestDeleteSuccess,
  unsubPending,
  requestDeleteFailed,
}) => (
  <Div
    className={cx('unsubscribe-confirm', { 'unsubscribe-confirm-whitelabel-container': embedLoadFailed })}
    style={backgroundColor ? {
      background: `linear-gradient(to bottom, ${backgroundColor}, ${chroma(backgroundColor).darken().hex()})`,
    } : undefined}
  >
    <Div className="unsubscribe-confirm-card">
      {logoUrl && <img alt="logo" src={logoUrl}/>}
      {(unsubscribed && !unsubPending && !responded) && 
        <Div>
          <H1>Unsubscribe Successful!</H1>
          <P>You will no longer receive email communication at <strong>{emailInput}</strong>.</P>
        </Div>
      }
      {(unsubRequestDeleteSuccess && !resubscribed && !responded) && <P>An admin has received your request to delete your data and will be processed within 30 days.</P>}
      {(requestDeleteFailed && !resubscribed && !responded) && <P className="subscription-confirm-error-message">We were unable to request data deletion, please contact an admin.</P>}
      {(resubscribed || responded) && <ResubRespondedEmail
                          emailInput={emailInput}
                          responded={responded}
                          resubscribed={resubscribed}
                          isGdprConsentRequired={isGdprConsentRequired}
                          requestDeleteFailed={requestDeleteFailed}/>
      }
      {(!unsubscribed || unsubPending) && !resubscribed && <ConfirmEmail
                          emailInput={emailInput}
                          onEmailConfirmChange={onEmailConfirmChange}
                          unsubscribePending={unsubPending}
                          unsubscribeError={unsubscribeError}
                          unsubscribeErrorMessage={unsubscribeErrorMessage}
                          cantUnsub={cantUnsub}
                          isGdprConsentRequired={isGdprConsentRequired}
                          unsubscribeChoice={unsubscribeChoice}
                          onUnsubChoiceChange={onUnsubChoiceChange}
                          confirmDisabled={confirmDisabled}
                          onConfirmClick={onConfirmClick}/>
      }
      {(unsubscribed && !unsubPending) && !responded && !resubscribed && <SelectReason handleResponseClick={handleResponseClick} />}
      {resubscribeError && <P className="subscription-confirm-error-message">{resubscribeErrorMessage}</P>}
      {(unsubscribed && cantResub) && <P className="subscription-confirm-error-message">There was a problem when trying to resubscribe you, please contact support to resubscribe.</P>}
      {(unsubscribed && !unsubPending && !responded) && <span onClick={onResubscribeClick}><A>Oops, resubscribe me</A></span>}
      <P>Email <Emoji emoji=":zap:" size={16} /> by <A href={POWERED_BY_URL} target="_blank">Drift</A></P>
    </Div>
  </Div>
)

const ResubRespondedEmail = ({
  emailInput,
  resubscribed,
  responded,
  isGdprConsentRequired
}) => (
  <Div>
    {resubscribed && 
      <Div>
        <H1>Resubscribe Successful!</H1>
        <P>You will receive email communication at <strong>{emailInput}</strong></P>
      </Div>
    }
    {responded &&
      <Div>
        <H1>Thanks for the feedback!</H1>
        <P>You will no longer receive email communication at <strong>{emailInput}</strong>.</P>
        <P>We appreciate you helping us get better.</P>
      </Div>
    }
    <Div className={'unsubscribe-responded' + (isGdprConsentRequired ? ' unsubscribe-responded-gdpr' : ' unsubscribe-responded-not-gdpr')}>
      <Icon name="check-circle"/>
    </Div>
  </Div>
)

const ConfirmEmail = ({
  emailInput,
  onEmailConfirmChange,
  unsubscribePending,
  onConfirmClick,
  unsubscribeError,
  unsubscribeErrorMessage,
  cantUnsub,
  isGdprConsentRequired,
  unsubscribeChoice,
  onUnsubChoiceChange,
  confirmDisabled,
}) => (
  <Div>
    <H1>Confirm unsubscribing from emails</H1>
    <P>Please take a moment to confirm that you no longer wish to receive email communication at <strong>{emailInput}</strong>.</P>
    {unsubscribeError &&
      <P className="subscription-confirm-error-message">{unsubscribeErrorMessage}</P>
    }
    {cantUnsub &&
      <P className="subscription-confirm-error-message">There was a problem when trying to unsubscribe you, please contact support to unsubscribe.</P>
    }
    <Div className="subscription-confirm-input-container">
      <Input value={emailInput} onChange={onEmailConfirmChange} className={cx('subscription-confirm-input-email', { 'subscription-confirm-input-gdpr' : isGdprConsentRequired })} />
      {isGdprConsentRequired &&
        <Div className="subscription-radio-choices">
          <Div className={cx('subscription-radio-choice', { 'subscription-radio-choice-selected' : unsubscribeChoice === unsubscribeChoices.UNSUB })} onClick={() => onUnsubChoiceChange(unsubscribeChoices.UNSUB)}>
            <Input
              type="radio"
              className="subscription-radio-input"
              name="unsub"
              checked={unsubscribeChoice === unsubscribeChoices.UNSUB}
            />
            <label>Unsubscribe Me</label>
          </Div>
          <Div className={cx('subscription-radio-choice', { 'subscription-radio-choice-selected' : unsubscribeChoice === unsubscribeChoices.UNSUB_AND_REQUEST_DELETE })} onClick={() => onUnsubChoiceChange(unsubscribeChoices.UNSUB_AND_REQUEST_DELETE)}>
            <Input
              type="radio"
              className="subscription-radio-input"
              name="unsub"
              checked={unsubscribeChoice === unsubscribeChoices.UNSUB_AND_REQUEST_DELETE}
            />
            <label>Unsubscribe and request my data be deleted</label>
          </Div>
        </Div>
      }
      <Button
        type="primary"
        disabled={confirmDisabled}
        loading={unsubscribePending}
        onClick={onConfirmClick}
        className={cx('subscription-confirm-button', { 'subscription-confirm-button-gdpr' : isGdprConsentRequired })}
      >
        Confirm
      </Button>
    </Div>
  </Div>
)

const SelectReason = ({
  handleResponseClick,
}) => (
  <Div>
    <P>If you have a moment, can you tell us why you unsubscribed?</P>
    <Div className="unsubscribe-confirm-reasons">
      {REASONS.map(reason =>
        <Button key={reason.emoji} type="subtle" onClick={() => handleResponseClick(reason.description)}><Emoji emoji={reason.emoji} size={16} />{reason.description}</Button>)
      }
    </Div>
  </Div>
)
